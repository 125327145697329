import axios from "../utils/axios";

export const getMonthlySummary: (
    start: string,
    end: string
) => Promise<any> = async (
    start: string,
    end: string
): Promise<any> => {
    const response = await axios.get(`api/monthly-summary`, {
        params: {
            start,
            end
        }
    });
    return response?.data || null;
}