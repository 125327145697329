import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import MainCard from "ui-component/cards/MainCard";
import {Typography, CircularProgress, Box, Button} from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import moment from 'moment';
import Logger from "utils/Logger";
import {getMonthlySummary} from "api/monthly-summaries";

/**
 * Function to update page with HTML content fetched from server.
 * Manages fetching multiple HTML files based on current and previous months.
 * Cleans and sanitizes the fetched HTML content before displaying on the page.
 * Displays loading spinner while fetching data.
 * Displays content on the page once data is fetched.
 */
const UpdatePage = () => {
    const [htmlContents, setHtmlContents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    // Fetches the HTML files
    useEffect(() => {

        // Function to extract everything from `<!DOCTYPE html>` to `</html>`
        const extractHtmlContent = (html) => {
            const regex = /<!DOCTYPE html>[\s\S]*<\/html>/;
            const match = html.match(regex);

            // Return the match or the default content if no match is found
            return match ? match[0] : html;
        };


        const fetchHtmlFiles = async () => {
            try {

                // Get start date in YYYY-MM format

                let startDate = moment().format('YYYY-MM');

                if (htmlContents.length > 0) {
                    startDate = moment(htmlContents[htmlContents.length-1].monthDate, 'YYYY-MM').subtract(1, 'months').format('YYYY-MM');
                }

                // Get the date 6 months before in YYYY-MM format
                const endDate = moment(startDate, 'YYYY-MM').subtract(5, 'months').format('YYYY-MM');

                // Fetch monthly summary data
                let results = await getMonthlySummary(startDate, endDate);

                if (results && results.length > 0) {
                    // Process each result, extract and sanitize HTML
                    const processedResults = results.map((content) => {
                        if (content.monthData) {
                            const extractedHtml = extractHtmlContent(content.monthData);
                            const sanitizedHtml = DOMPurify.sanitize(extractedHtml); // Sanitize the HTML content

                            return {
                                ...content, // Retain the original content
                                sanitizedHtml: sanitizedHtml // Add sanitized HTML
                            };
                        } else {
                            return content; // If monthData is null, return the content as is
                        }
                    });

                    // Set the processed and sanitized HTML content to state
                    setHtmlContents(prevState => [...prevState, ...processedResults]);
                }
            } catch (e) {
                Logger.error(e);
            } finally {
                setLoading(false);
            }

        };

        fetchHtmlFiles();
    }, [page]);

    const handleViewMoreClick = () => {
        setLoading(true);
        setPage(prevState => prevState + 1);
    };

    return (
        <MainCard>
            <Typography variant="h1" pb={4} textAlign={"center"}>
                Casabio updates
            </Typography>

            {/* Show loading spinner until data is fetched */}
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                htmlContents.length > 0 ? (
                    htmlContents.map((content, index) => (
                        <Box key={index} py={1}>
                            <SubCard sx={{ display: "flex" }}>
                                <Typography variant="h3" py={1}>
                                    {moment(`${content.monthDate}-01`, 'YYYY-MM-DD').format('MMMM YYYY')}
                                </Typography>
                                {content.sanitizedHtml ? (
                                    <div dangerouslySetInnerHTML={{ __html: content.sanitizedHtml }} />
                                ) : (
                                    <Typography variant="body1" color="textSecondary">
                                        No report found for this month.
                                    </Typography>
                                )}
                            </SubCard>
                        </Box>
                    ))
                ) : (
                    <SubCard>
                        <Typography variant="body1" py={1}>
                            No update reports found.
                        </Typography>
                    </SubCard>
                )
            )}

            {/* Only show the "View More" button if htmlContents is not empty */}
            {htmlContents.length > 0 && !loading && (
                <Box display="flex" justifyContent="center" mt={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleViewMoreClick}
                        disabled={loading}
                        sx={{
                            height: 40,
                            width: 100,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 0,
                        }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'View More'}
                    </Button>
                </Box>
            )}
        </MainCard>
    );
};

export default UpdatePage;
