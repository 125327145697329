import { lazy } from 'react';

// project imports
import AdminGuard from 'utils/route-guard/AdminGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const AdminDashboard = Loadable(lazy(() => import('views/admin/dashboard')));
const BookUploadPage = Loadable(lazy(( ) => import('views/admin/uploadBook/uploadStep1')));
const BookUpload2Page = Loadable(lazy(( ) => import('views/admin/uploadBook/uploadStep2')));

// ==============================|| Admin ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: 'admin/dashboard',
            element: <AdminDashboard />
        },
        {
            path: '/admin/books/upload',
            element: <BookUploadPage />
        },
        {
            path: '/admin/books/upload2',
            element: <BookUpload2Page />
        }

    ]
};

export default AdminRoutes;
