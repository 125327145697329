import { useEffect, useContext, useState } from 'react';
import { getUserProfile } from 'api/userProfile';
import { verifyToken } from 'contexts/JWTContext';
import AuthContext from 'contexts/JWTContext';
import { LOGIN } from 'store/actions';


const useAuth = () => {
    const context = useContext(AuthContext);
    const { dispatch } = context;

    const [user, setUser] = useState(null);


    useEffect(() => {
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (!user && refreshToken && verifyToken(refreshToken)) {
            getUserProfile().then((response) => {
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response[0],
                    },
                });

                setUser(response[0])
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, getUserProfile]);

    if (!context) throw new Error("context must be use inside provider");

    return { ...context, user }; // return user from state instead of context
};

export default useAuth;