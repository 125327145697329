import { FormattedMessage } from 'react-intl';

// assets
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import {environment} from "config";
// ==============================|| MENU ITEMS - SITE DETAILS ||============================== //

const icons = {
    ManageSearchOutlinedIcon
};

const siteDetails = {
    id: 'site-details',
    title: <FormattedMessage id="site-details-title"/>,
    caption: <FormattedMessage id="site-details-caption"/>,
    icon: icons.ManageSearchOutlinedIcon,
    type: 'group',
    children: [
        {
            id: 'browse',
            title: <FormattedMessage id="Site Details"/>,
            type: 'collapse',
            icon: icons.ManageSearchOutlinedIcon,
            children: [
                {
                    id: 'about-us',
                    title: <FormattedMessage id="About Us"/>,
                    type: 'item',
                    url: '/about',
                    target: false,
                },
                {
                    id: 'calendar',
                    title: <FormattedMessage id="Calendar"/>,
                    type: 'item',
                    url: '/calendar',
                    target: false,
                },
                // {
                //     id: 'donations',
                //     title: <FormattedMessage id="Donations"/>,
                //     type: 'item',
                //     url: '/donations',
                //     target: false,
                // },
                {
                    id: 'contact-us',
                    title: <FormattedMessage id="Contact Us"/>,
                    type: 'item',
                    url: '/contact-us',
                    target: false,
                },
                {
                    id: 'public-api',
                    title: <FormattedMessage id="Public API"/>,
                    type: 'item',
                    url: `${environment.API_URL}/api`,
                    target: true,
                },
                {
                    id: 'site-status',
                    title: <FormattedMessage id="Site Status"/>,
                    type: 'item',
                    url: 'https://stats.uptimerobot.com/d5omu5ETY4',
                    target: true,
                },
                {
                    id: 'site-updates',
                    title: <FormattedMessage id="Updates"/>,
                    type: 'item',
                    url: '/updates',
                    target: false,
                }
            ]
        }
    ]
}

export default siteDetails;